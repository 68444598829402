<template>
  <div class="container col-md-12 notice-panel">
    <b-card v-for="item in list" :key="item.noticeId"
            :title="item.noticeTitle"
            tag="article"
            class="mb-2"
    >
      <b-card-text>
        <!--        <div v-html="item.noticeContent"></div>-->
        <p>{{ item.createDate }}</p>
      </b-card-text>
      <el-button type="primary" size="mini" @click="goDetail(item.noticeId)">查看详情 →</el-button>
    </b-card>
  </div>
</template>

<script>
import {getList} from "@/api/notice";

export default {
  name: "Notice",
  data() {
    return {
      list: []
    }
  },
  mounted() {
    // debugger
    const type = this.$route.params.type;
    getList({'type': type}).then(data => {
      // debugger
      this.list = data;
    })
  },
  methods: {
    goDetail: function (id) {
      this.$router.push('/noticeDetail/' + id);
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/less/notice.less";
</style>